<template>
    <div>
		<DateRange
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="loadPayments"
        />

        <CustomTable
            id_table="check_deposit"
            :items="check_deposit_formatted"
            :busy.sync="table_busy"
            primaryKey="checkdeposit_id"
            :hrefsRoutes="config_table_hrefs"
            :hide_if_empty="true"
            ref="check_deposit"
        />
    </div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Accounting from "@/mixins/Accounting.js"
    import Shutter from "@/mixins/Shutter.js"
    import _merge from "lodash/merge"
    import Common from '@/assets/js/common.js'

    export default {
        name: "list",
        mixins: [Navigation, Payment, Accounting, Shutter],
        props: [],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleAccoutingPlan: this.getTrad("compta.select_accounting_plan"),
                labelTitleConfiguration: this.getTrad("check.select_configuration"),
                check_deposit_status: this.getConfig('check_deposit_status'),
                check_deposit_status_format: [],
                check_deposit: null,
                check_deposit_formatted: null,
                
                table_busy: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                isLoading: false,
                accounting_plans: [],
                configurations: [],
                
                invoice_ids: null,
                invoice_nums: null,
				config_table_hrefs: {
                    'payment.tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'payment.tiers.tiers_id'
                        }
                    }
                },
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
                events_tab: {
                    'TableAction::goToDeleteCheckDeposit': this.deleteCheckDepositForm,
                    'TableAction::goToCashCheckDeposit': this.cashCheckDepositForm,
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                
                this.loadPayments()
            },
            async loadPayments() {
				this.check_deposit = await this.listCheckDeposit(null, this.start_date, this.end_date)
                this.formatCheckDeposit(this.check_deposit)
                this.table_busy = false
            },
            

            async deleteCheckDepositForm(params)
            {
                let result = await this.deleteCheckDeposit(params)
                if(result != null)
                {
                    this.$refs["check_deposit"].unselectAll()
                    this.loadPayments()
                    this.successToast('toast.check_deposit_deleted_success')
                }
            },

            //Encaissement des chèques
            async cashCheckDepositForm(params)
            {
                let checkdeposit_ids = params.checkdeposit_ids.split(',')
                for (let i = 0; i < checkdeposit_ids.length; i++) 
                {
                    let check_deposit = this.check_deposit.filter(check => check.checkdeposit_id == checkdeposit_ids[i])[0]
                    if(check_deposit.status.checkdepositstatus_code == "ENC")
                    {
                        this.failureToast('check.pas_encaissement')
                        return false
                    }
                }

                let result = await this.cashCheckDeposit(params)
                if(result)
                {
                    this.$refs["check_deposit"].unselectAll()
                    this.successToast('toast.check_deposit_encaissement_success')
                    this.loadPayments()
                }

            },
            async formatCheckDeposit (check_deposit) {
                let checkDepositFormated = []

                if(!check_deposit)
                {
                    return []
                }
                for (let i = 0; i < check_deposit.length; i++) {
                    const temp = check_deposit[i]
                    temp.status.checkdepositstatus_label = this.getTrad(temp.status.checkdepositstatus_label)
                    let invoices_tab = []
                    temp.payment.forEach(payment => {
                        invoices_tab = invoices_tab.concat(payment.invoices)
                    })

                    let invoices_nums = this.getArrayObjProperty(invoices_tab, "invoice_num")

                    let invoices = []
                    temp.formatted_invoices_nums = invoices_nums.map(function(value){
                        if(invoices.indexOf(value) == -1)
                        {
                            invoices.push(value)
                            return value
                        }
                    }).join(", ")

                    let amount = temp.payment.reduce((sum, p) => sum + p.payment_amount*100, 0)
                    temp.amount = await this.priceFormat(amount/100)

                    checkDepositFormated.push(temp);
                }

                this.check_deposit_formatted = checkDepositFormated
            }
        },
        computed: {
            computed_checks: function(){
                if(this.errors && this.errors.indexOf('Check') > -1)
                {
                    return false
                }

                return null
            }
        },

        watch: {
            
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
        }
    }
</script>
